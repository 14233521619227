import React, { useState } from "react";
import { Form, Button, Modal, Col, Row, Divider, Upload, Checkbox, message } from "antd";
import useStore from "../../../../../../store";
import { observer } from "mobx-react";
import { vsmNotify, vsmSentInvoice } from "../../../../../../config/messages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import InputComponent from "../../../../../../component/InputComponent";
import moment from "moment";
import { UploadOutlined } from "@ant-design/icons";

const CustomUpload = ({ label, name, required, fileList, setFileList, rules }) => {
	const handleImageChange = (info) => {
		let newFileList = info.fileList.filter((file) => {
			if (file.size / 1024 / 1024 > 1) {
				message.error(`${file.name} is larger than 1MB and has been removed.`);
				return false;
			}
			return true;
		});
		newFileList = newFileList.slice(-2);
		setFileList(newFileList);
	};

	const imageProps = {
		beforeUpload: () => false,
		onChange: handleImageChange,
		fileList: fileList,
	};

	return (
		<Form.Item label={label} name={name} required={required} rules={rules}>
			<Col>
				<Upload accept=".png,.jpeg,.jpg" {...imageProps}>
					<Button disabled={fileList.length > 0} icon={<UploadOutlined />}>Upload</Button>
				</Upload>
			</Col>
		</Form.Item>
	);
};

const SentInvoiceComponent = observer((props) => {
	const [form] = Form.useForm();
	const {
		ManageZFormsStore,
	} = useStore();
	const [saving, setSaving] = useState();
	const [invoiceFileList, setInvoiceFileList] = useState([]);
	const [rtoFileList, setRtoFileList] = useState([]);
	const [insuranceFileList, setInsuranceFileList] = useState([]);
	const [debitNoteFileList, setDebitNoteFileList] = useState([]);
	const dateFormat = "DD/MM/YYYY";

	// Make function call to delete existing record
	const handleSubmit = (data) => {
		setSaving(true);
		const formData = new FormData();
		formData.append('inv_no', form.getFieldValue("inv_no"));
		formData.append('inv_date', moment(form.getFieldValue("inv_date")).format("YYYY-MM-DD"));
		formData.append('is_send_email', form.getFieldValue("is_send_email") ? 1 : 0);
		formData.append('is_send_wp', form.getFieldValue("is_send_wp") ? 1 : 0);

		if (invoiceFileList.length > 0) {
			formData.append('inv_copy', invoiceFileList[0].originFileObj);
		}
		if (rtoFileList.length > 0) {
			formData.append('rto_receipt', rtoFileList[0].originFileObj);
		}
		if (insuranceFileList.length > 0) {
			formData.append('ins_policy', insuranceFileList[0].originFileObj);
		}
		if (debitNoteFileList.length > 0) {
			formData.append('debit_note', debitNoteFileList[0].originFileObj);
		}
		ManageZFormsStore.SentInvoice(formData, ManageZFormsStore.viewValues.id)
			.then((data) => {
				close();
				vsmNotify.success({
					message: data.STATUS.NOTIFICATION[0],
				});
			})
			.catch((e) => {
				if (e.errors) {
					form.setFields(e.errors);
				}
			})
			.finally(() => {
				setSaving(false);
			});
	};

	const disabledDate = (current) => {
		return current && ((current > moment().endOf("day")) || (current.isBefore(ManageZFormsStore.viewValues.date)));
	};

	// reset form and close add form
	const close = () => {
		props.close();
		form.resetFields();
		setInvoiceFileList([]);
		setRtoFileList([]);
		setInsuranceFileList([]);
		setDebitNoteFileList([]);
	};

	return ManageZFormsStore.viewValues ? (
		<Modal
			centered
			className="addModal"
			width={'40%'}
			title={"Sent Invoice (" + ManageZFormsStore.viewValues.id + ")"}
			visible={props.visible}
			closeIcon={<FontAwesomeIcon icon={faTimes} />}
			onCancel={props.close}
			cancelButtonProps={{ style: { display: "none" } }}
			okButtonProps={{ style: { display: "none" } }}
			footer={[
				<Button
					key="2"
					htmlType="button"
					className="cancelBtn mr-35"
					onClick={close}
				>
					No
				</Button>,
				<Button
					key="1"
					form="sentInvoiceForm"
					loading={saving}
					htmlType="submit"
					type="primary"
				>
					Yes
				</Button>,
			]}
		>
			<Form
				form={form}
				id="sentInvoiceForm"
				labelCol={{ span: 24 }}
				onFinish={handleSubmit}
			>
				{
					<>
						<Row align="middle">
							<Col span={24} className="text-center">
								<InputComponent
									type="text"
									required
									label="Invoice No"
									placeholder="Invoice No"
									name="inv_no"
									rules={vsmSentInvoice.validation.inv_no}
								/>
							</Col>
							<Col span={24} className="text-center">
								<InputComponent
									type="date"
									mode="date"
									required
									format={dateFormat}
									disabledDate={disabledDate}
									label="Invoice Date"
									placeholder="Invoice Date"
									name="inv_date"
									rules={vsmSentInvoice.validation.inv_date}
								/>
							</Col>
						</Row>
						<Divider />
						<Row align="middle">
							<h2>Upload Documents</h2>
						</Row>
						<Row align="middle">
							<Col span={12}>
								<CustomUpload
									label="Invoice Copy"
									name="inv_copy"
									required={true}
									fileList={invoiceFileList}
									setFileList={setInvoiceFileList}
									rules={vsmSentInvoice.validation.inv_copy}
								/>
							</Col>
							<Col span={12}>
								<CustomUpload
									label="RTO Tax Receipt"
									name="rto_receipt"
									required={true}
									fileList={rtoFileList}
									setFileList={setRtoFileList}
									rules={vsmSentInvoice.validation.rto_receipt}
								/>
							</Col>
						</Row>
						<Divider />
						<Row align="top">
							<Col span={12}>
								<CustomUpload
									label="Insurance Policy"
									name="ins_policy"
									required={ManageZFormsStore.viewValues.booking_ledger?.loan_source?.id === 20 ? true : false}
									fileList={insuranceFileList}
									setFileList={setInsuranceFileList}
									rules={[
										({ getFieldValue }) => ({
											validator(_) {
												const insPolicy = getFieldValue("ins_policy");
												if (!insPolicy && ManageZFormsStore.viewValues.booking_ledger?.loan_source?.id === 20) {
													return Promise.reject(
														"It can not be blank"
													);
												}
												return Promise.resolve();
											},
										}),
									]}
								/>
								<p className="text-danger">Note: It's mandatory if insurance is selected to be in-house.</p>
							</Col>
							<Col span={12}>
								<CustomUpload
									label="Debit Note (Optional)"
									name="debit_note"
									required={false}
									fileList={debitNoteFileList}
									setFileList={setDebitNoteFileList}
								/>
							</Col>
						</Row>
						<Divider />
						<Row align="middle">
							<Col span={12}>
								<Form.Item
									required
									valuePropName="checked"
									name="is_send_email"
									initialValue={true}
								>
									<Checkbox>
										Send Email
									</Checkbox>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item
									required
									valuePropName="checked"
									name="is_send_wp"
									initialValue={true}
								>
									<Checkbox>
										Send WhatsApp
									</Checkbox>
								</Form.Item>
							</Col>
						</Row>
					</>
				}
			</Form>
		</Modal>
	) : null;
});

export default SentInvoiceComponent;
